
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import DatePicker from '@/components/DatePicker.vue';
import TaxDeclarationService from '@/services/TaxDeclarationService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({
  name: 'RemoveDeclarationDialog',
  components: {DatePicker},
})
export default class RemoveDeclarationDialog extends Vue {
  loading: boolean = false;

  @Prop()
  declarationId!: number | null;

  async accept() {
    this.loading = true;
    try {
      await TaxDeclarationService.remove(this.declarationId as number);
      notify(
          NotificationTypes.success,
          this.$t('SYSTEM_DELETED'),
          this.$t('SYSTEM_TAX_DECLARATION_HAS_BEEN_DELETED'),
      );
      this.$emit('removed');
    } catch (e) {
      resolveError(e, 'remove');
    } finally {
      this.loading = false;
    }
  }

  @Emit('close')
  close() {
    return;
  }
}
